<template>
  <div class="animated fadeIn">
    <b-container>
      <b-card>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
        ></loading>
        <h5 slot="header" class="mb-0">
          <b-button
            size="sm"
            :to="{
              name: 'admin.customer-loan-accounts.detail',
              params: { id: this.$route.params.id },
            }"
            class="mr-2"
            ><i class="fa fa-chevron-left"></i> Буцах</b-button
          >
        </h5>
        <b-form @submit.stop.prevent="saveData">
          <h4 class="mb-0">Нэмэлт барьцаа</h4>
          <hr class="mt-2" />

          <b-row>
            <b-col>
              <b-form-group
                id="input-group-type"
                label="ҮХХ хэлбэр"
                label-for="input-type"
              >
                <b-select v-model="form.depositType" :options="depositTypeList"></b-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-ownership"
                label="Өмчлөлийн хэлбэр"
                label-for="input-ownership"
              >
              <b-select v-model="form.depositOwnership" :options="depositOwnershipList"></b-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="input-group-address"
                label="Хаяг"
                label-for="input-address"
              >
                <b-input v-model="form.address" placeholder="Бичнэ үү"></b-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="input-group-square"
                label="Хэмжээ /мкв/"
                label-for="input-square"
              >
                <money
                  id="input-factory_year"
                  class="form-control"
                  v-model="form.square"
                  type="text"
                  required
                  placeholder="Бичнэ үү"
                  suffix=""
                  thousands=""
                  :precision="2"
                ></money>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-register"
                label="Улсын бүртгэлийн дугаар"
                label-for="input-register"
              >
                <b-input v-model="form.registerNumber" placeholder="Бичнэ үү"></b-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="input-group-cert"
                label="Гэрчилгээний дугаар"
                label-for="input-cert"
              >
                <b-input v-model="form.certNumber" placeholder="Бичнэ үү"></b-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-certDate"
                label="Гэрчилгээ авсан огноо"
                label-for="input-certDate"
              >
                <b-datepicker v-model="form.certDate"
                placeholder="Сонгоно уу"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  autocomplete="off"></b-datepicker>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button type="submit" variant="success">Хадгалах</b-button>
        </b-form>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "new-loan",
  data: function() {
    return {
      isLoading: false,
      form: {
        id: 0,
        depositType: null,
        depositOwnership: null,
        address: null,
        square: 0,
        registerNumber: null,
        certNumber: null,
        certDate: null
      },
      depositTypeList: [
        { value: 'APARTMENT', text: 'Орон сууц' },
        { value: 'FENCE_HOUSE', text: 'Хашаа байшин' },
        { value: 'HOUSE', text: 'Амины сууц' },
        { value: 'PLACE_OWNERSHIP', text: 'Өмчлөх эрхтэй газар' },
        { value: 'PLACE_OWN', text: 'Эзэмших эрхтэй газар' },
      ],
      depositOwnershipList: [
        { value: 'OWN', text: 'Өөрийн' },
        { value: 'PARTNER', text: 'Хамтрангийн' },
        { value: 'CREDENTIAL', text: 'Итгэмжлэлийн' }
      ]
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData: function() {
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL + "LoanWebService/get_deposit_extra_data",
          {
            params: { id: this.$route.params.id },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            console.log(response)
            this.isLoading = false;
            this.$data.form = response.body;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    saveData: function() {
      this.isLoading = true;
      //console.log(this.$data.form)
      this.$http
        .post(
          this.$config.API_URL + "LoanWebService/create_deposit_extra",
          {
            loanAccountId: this.$route.params.id,
            id: this.$data.form.id,
            data: JSON.stringify(this.$data.form),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            console.log(response.body)
            if (response.body.success) {
              //console.log('asdf')
              this.$router.push({
                name: "admin.customer-loan-accounts.detail",
                params: { id: this.$route.params.id },
              });
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
